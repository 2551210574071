<template>
  <div class="auth-wrapper auth-v2">
    <b-modal
      v-model="showDoubleAuthModal"
      ok-only
      ok-title="Accept"
      centered
      size="md"
      :hide-footer="true"
      title="Code de double authentification"
      lazy
      static
    >
      <b-row class="my-2 d-flex align-items-center justify-content-center text-cente">
        <b-col :cols="4">
          <b-form-input v-model="code"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="d-flex align-items-center justify-content-center text-center">
        <b-button
          variant="primary"
          class="mb-1"
          @click="checkCode()"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-50"
          />
          <span>Confirmer le code</span>
        </b-button>
      </b-row>
    </b-modal>
    <b-modal
      v-model="showActivateDoubleAuthModal"
      ok-only
      ok-title="Accept"
      centered
      size="md"
      :hide-footer="true"
      title="Activer la double authentification ?"
      lazy
      static
    >
      <b-row class="d-flex align-items-center justify-content-center text-center">
        <b-col cols="6">
          <b-img :src="qrCode" />
        </b-col>
      </b-row>
      <b-row class="my-1 d-flex align-items-center justify-content-center text-center">
        <b-col :cols="4">
          <b-form-input v-model="code" />
        </b-col>
      </b-row>
      <b-row class="d-flex align-items-center justify-content-center text-center">
        <b-col cols="6">
          <b-button
            variant="white"
            class="mb-1"
            @click="goHome()"
          >
            <feather-icon
              icon="XIcon"
              class="mr-50"
            />
            <span>Pas maintenant</span>
          </b-button>
        </b-col>
        <b-col cols="6">
          <b-button
            variant="primary"
            class="mb-1"
            @click="activeDoubleAuth()"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            <span>Confirmer le code</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Kipli
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome to Kipli Dashboard ! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Merci de vous connecter pour accéder à l'application.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="victor@kipli.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Mot de passe</label>
                  <!-- <b-link :to="{name:'auth-forgot-password-v2'}">
                    <small>Forgot Password?</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Se souvenir de moi
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Se connecter
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'page-auth-register-v2'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BModal,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BModal,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      code: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      accessToken: null,
      showDoubleAuthModal: false,
      showActivateDoubleAuthModal: false,
      // validation rulesimport store from '@/store/index'
      required,
      email,
      qrCode: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      console.log(process.env)
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    goHome() {
      localStorage.setItem('accessToken', this.accessToken)
      localStorage.setItem('type', this.me?.type)
      window.location = '/'
    },
    async checkCode() {
      try {
        await this.$http.post('/user/check', { code: this.code }, { headers: { Authorization: this.accessToken } })
        localStorage.setItem('accessToken', this.accessToken)
        localStorage.setItem('type', this.me?.type)
      } catch (err) {
        console.log(err)
      }
    },
    async activeDoubleAuth() {
      try {
        await this.$http.post('/user/check', { code: this.code, activate: true }, { headers: { Authorization: this.accessToken } })
        localStorage.setItem('accessToken', this.accessToken)
        localStorage.setItem('type', this.me?.type)
      } catch (err) {
        console.log(err)
      }
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(async success => {
        if (success) {
          const { accessToken, twoFactorEnable, qrCode } = await this.$http.post('/user/login', { email: this.userEmail, password: this.password })
          // const res = user.login({
          //   email: this.userEmail, password: this.password,
          // })
          this.qrCode = qrCode
          this.accessToken = accessToken
          localStorage.removeItem('accessToken')
          if (twoFactorEnable) {
            this.showDoubleAuthModal = true
          } else {
            this.showActivateDoubleAuthModal = true
          }
          console.log(twoFactorEnable)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
